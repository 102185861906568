import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'src/i18n';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import useNewTracking from 'components/Globals/Analytics';
import { GOOGLE_OLD_TRACKING_SERVICES, SUB_COMPONENTS, COMPONENTS } from 'components/Globals/Analytics/constants';
import Link from 'components/uiLibrary/Link'; // TODO: Change to use LinkButton
import Typography from 'components/uiLibrary/Typography';
import LanguageSelector from 'components/Globals/LanguageSelector';
import Button from 'components/uiLibrary/Button';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import CollapsableList from 'components/uiLibrary/CollapsableList';

import { TRACK_EVENTS, GLOBAL_HEADER_LINKS } from 'utils/tracking';

import route from 'constants/routes';
import { TP, BASE_PAGE_ROUTES, ENTITY_TYPE } from 'constants/index';

import { useDialogs } from 'utils/hooks/useDialogs';
import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import classes from './NonLoggedInSection.module.scss';

const UpdateProfile = dynamic(() => import('components/Globals/Layout/UpdateProfile'));

const NonLoggedInSection = ({ trackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isTablet } = useDeviceTypeLayouts();
  const [openUpdateProfileModal, setOpenUpdateProfileModal] = useState(false);
  const updateProfileRef = useRef();
  const router = useRouter();
  const { setIsLoginDialog, setIsNewUser } = useDialogs();
  const track = useNewTracking();
  const { navigate, entityType } = usePageContext();

  const castingToolLinkProps = navigate.getLinkProps({
    baseRoute: BASE_PAGE_ROUTES.CASTING,
    onlyLinkProps: true,
  });

  return (
    <div ref={updateProfileRef} className={classes.containersContainer}>
      <CollapsableList wrapper="ul" className={classes.container} extraSpace={0}>
        <li>
          <Link
            {...castingToolLinkProps}
            className={classnames(classes.link, {
              [classes.isSelected]:
                router.route === route.CASTING_TOOL && entityType !== ENTITY_TYPE.ARTIST && !openUpdateProfileModal,
            })}
            disableUnderline
            trackingData={{
              ...trackingData,
              subComponent: SUB_COMPONENTS.CASTING_TOOL_CTA,
            }}
            onClick={() => {
              track.click(
                {
                  name: TRACK_EVENTS.CLICK,
                  data: {
                    id: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_CASTING_TOOL_LINK,
                  },
                },
                GOOGLE_OLD_TRACKING_SERVICES,
              );
            }}
          >
            <Typography size={14} weight="medium" color="secondary">
              {t(`${TP}.FN_CASTINGTOOL`)}
            </Typography>
          </Link>
        </li>
        <li>
          <Link
            className={classnames(classes.link, {
              [classes.isSelected]: router.route === route.RENTALS && !openUpdateProfileModal,
            })}
            href={route.RENTALS}
            disableUnderline
            trackingData={{
              ...trackingData,
              subComponent: SUB_COMPONENTS.RENTAL_CTA,
            }}
            onClick={() => {
              track.click(
                {
                  name: TRACK_EVENTS.CLICK,
                  data: {
                    id: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_RENTALS_LINK,
                  },
                },
                GOOGLE_OLD_TRACKING_SERVICES,
              );
            }}
          >
            <Typography size={14} weight="medium" color="secondary">
              {t(`${TP}.m_RENTALS`)}
            </Typography>
          </Link>
        </li>
        <li data-show-always="true">
          <Button
            variant="tertiary"
            leftIcon={isTablet ? <SpriteIcon icon="pencil" /> : null}
            className={classnames(classes.updateProfileBtn, {
              [classes.isSelected]: openUpdateProfileModal,
            })}
            trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.UPDATE_PROFILE_CTA }}
            onClick={() => {
              setOpenUpdateProfileModal(true);
              track.click(
                {
                  name: TRACK_EVENTS.CLICK,
                  data: {
                    id: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_UPDATE_PROFILE,
                  },
                },
                GOOGLE_OLD_TRACKING_SERVICES,
              );
            }}
          >
            {!isTablet && (
              <Typography size={14} weight="medium" color="secondary">
                {t(`${TP}.UPDATE_PROFILE`)}
              </Typography>
            )}
          </Button>
        </li>
        <li data-show-always="true">
          <LanguageSelector />
        </li>
        <li data-show-always="true">
          <Typography
            size={14}
            weight="medium"
            color="secondary"
            onClick={() => {
              setIsLoginDialog({ isOpen: true });
              setIsNewUser(false);
              track.click(
                {
                  name: TRACK_EVENTS.CLICK,
                  data: {
                    id: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_LOGIN_CLICK,
                  },
                },
                GOOGLE_OLD_TRACKING_SERVICES,
              );

              track.click({
                ...trackingData,
                component: COMPONENTS.LOGIN_CTA,
                subComponent: '',
              });
            }}
            className={classes.loginLink}
          >
            {t(`${TP}.m_LOGIN`)}
          </Typography>
        </li>
      </CollapsableList>
      {openUpdateProfileModal && (
        <UpdateProfile
          reference={updateProfileRef}
          open={openUpdateProfileModal}
          onClose={() => {
            setOpenUpdateProfileModal(false);
          }}
        />
      )}
    </div>
  );
};

export default NonLoggedInSection;
